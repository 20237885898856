.map_container_size {
  width: 90vw;
  height: auto;
}

.toggleable {
  font-size: 12px;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 55px;
  left: 50px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: 1px solid black;
  border-radius: 10px;
  background-color: #427b01;
  display: none;
}

@media (max-width: 556px) {
  .toggleable {
    display: inline;
  }
}