.col_size {
  width: 100%;
}

.outer_container_size {
  width: 100%;
}

.percentage_text {
  color: black;
}

.question_font_size {
  font-size: min(4vw, 26px);
}

.statement_text {
  text-align: center;
  font-size: calc(1.325rem + .9vw);
  margin-bottom: 0px !important;
}

.subtitle_text {
  text-align: center;
  font-style: italic;
}

.title_text {
  text-align: center;
  text-decoration-line: underline;
  margin-bottom: 0px;
}
