.inputsize {
  --iphone-width: 320px;
  width: var(--iphone-width);
  min-width: calc(var(--iphone-width) - 50);
}

.emailpasswordcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-evenly;
  max-width: 100vw;
}

.firstlastnamecontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-evenly;
  max-width: 100vw;
}

@media (max-width: 556px) {
  .emailpasswordcontainer {
    justify-content: center;
  }
}