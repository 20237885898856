.outer_container_size {
  padding: 10px 0px 0px 0px !important;
  width: 25vw;
}

.search_container_size {
  margin: 10px 5px 5px auto !important;
  width: 95%;
}

.search_icon_size {
  margin-right: 5px;
  font-size: min(4vw, 20px);
}

.list_container_size {
  height: min-content;
  overflow-x: hidden;
  width: 99%;
}

.list_group_size {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  /* Necessary to overcome scrolling behavior that would overflow the top of the list */
  justify-content: start !important;
}

.outer_container_border {
  border-right: 1px solid gray !important;
}

@media (max-width: 556px) {
  .outer_container_size {
    width: 100vw;
  }
}