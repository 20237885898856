.container_size {
  width: 45vw;
}

.inner_container_size {
  width: 100%;
}

.search_field_size {
  max-width: 45vw;
}

@media (max-width: 556px) {
  .container_size {
    width: 90vw;
  }

  .search_field_size {
    max-width: 90vw;
  }
}